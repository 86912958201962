/* ./src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


html {
    font-size: 16px;
}



@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .btn {
        @apply px-4 py-2 text-white rounded cursor-pointer;
    }
    /* .component {
        @apply border border-dashed border-blue-900;
    } */
    .panel {
        @apply my-7;
    }
    .selected {
        @apply font-bold border border-gray-400 bg-white rounded;
    }
    .roundIcon {
        @apply h-5 w-5 p-0.5 rounded-full;
    }
    /* Headers */
    .h2 {
        @apply font-bold tracking-tight text-gray-900;
    }
    .h3 {
        @apply font-bold tracking-tight text-gray-900;
    }
    .grayLink {
        @apply font-bold text-gray-500;
    }
    .container {
        @apply max-w-8xl px-5 sm:px-12 xl:px-0 mx-auto;
    }
    .containerSmall {
        @apply max-w-4xl px-3 sm:px-12 mx-auto;
    }
    .containerSuperSmall {
        @apply max-w-xl px-3 sm:px-12 mx-auto;
    }
}

@layer utilities {
    .w-270 {
        width: 270px;
    }
    .h-152 {
        height: 152px;
    }
}

@layer base {
    * {
        font-family: 'Inter', sans-serif;
    }
}
