.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    /* color: #61dafb; */
    color: rgb(96, 165, 250);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.caruselNavRight {
    width: 30px;
    height: 30px;
    /* display: block; */
    /* position: absolute; */
    right: 15px;
    top: calc(25%);
}


/* TODO: we need to keep it center all the time (extra test) */

@media (min-width: 1280px) {
    .caruselNavRight {
        width: 30px;
        height: 30px;
        /* display: block; */
        /* position: absolute; */
        right: 15px;
        top: calc(25% + 13px);
    }
}

.caruselNavLeft {
    width: 30px;
    height: 30px;
    /* display: block; */
    /* position: absolute; */
    left: 15px;
    top: calc(25% + 13px);
}

@media (min-width: 1280px) {
    .caruselNavLeft {
        width: 30px;
        height: 30px;
        /* display: block; */
        /* position: absolute; */
        left: 15px;
        top: calc(25% + 15px);
    }
}

.separator {
    height: 1px;
    width: 100%;
    background-color: #e6e7eb;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active) {
    opacity: 0.6;
}


/* .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
} */

.markdownHtml {
    font-family: Inter-Regular_;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    /* letter-spacing: -0.69px; */
    letter-spacing: -0.05em;
    color: #6b7280;
}

.markdownHtml h1,
h2,
h3,
h4,
h5,
h6 {
    /* text-align: left; */
    color: #111827;
    font-weight: 700;
}

h1 {
    font-size: 2.25em;
}

h2 {
    font-size: 1.75em;
}

h3 {
    font-size: 1.55em;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 0.9em;
}

.markdownHtml a {
    color: rgb(96, 165, 250);
    font-weight: 500;
}

.markdownHtml p {
    padding: 20px 0px;
}

.markdownHtml img {
    text-align: center;
    margin: 0 auto;
    padding: 20px;
}

.markdownHtml ul,
.markdownHtml ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
}

.markdownHtml ol {
    list-style-type: decimal;
}

.markdownHtmlMainHeadline {
    text-align: left;
}

.markdownHtmlMainHeadline a {
    /* color: #61dafb; */
    color: rgb(96, 165, 250);
}

.markdownHtmlMainHeadline strong {
    color: black;
}

.markdownHtmlMainHeadline p {
    padding: 10px 0px;
}

.markdownHtmlMainHeadline img {
    text-align: center;
    margin: 0 auto;
    padding: 20px 0px;
}

.react-multi-carousel-list {
    /* width: calc(100% + 85px); */
}

.carouselItem {
    margin-right: 20px;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.no-scrollbar::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.bg-greeny-500 {
    background-color: #8ebaad;
}

.bg-greeny-500 {
    border-color: #8ebaad;
}

.textGreen {
    color: #8ebaad;
}

.bgBlue {
    background-color: #569fee;
}

.textBlue {
    color: #569fee;
}

.bgLightGray {
    /* background-color: #f9fafc; */
    background-color: #f6f6f6;
}

.bgLightGrayDays {
    background-color: #f9fafc;
}

.extendRight {
    position: absolute;
    width: calc(100% + 135px);
}

.debug {
    /* display: none; */
    opacity: 0.1;
    position: absolute;
    /* height: 100vh;
    width: 100vw; */
    top: 0px;
    width: 100%;
    height: 6000px;
    /* height: 100vh; */
}

.debug .block {
    background-color: #eea4a4;
    /* height: 100vh; */
    text-align: center;
    border-right: 1px solid black;
    border-left: 1px solid black;
    box-sizing: border-box;
}

.mlSpecial {
    margin-left: calc(50vw - 40rem + 2.5rem);
}

@media (max-width: 1280px) {
    .mlSpecial {
        margin-left: 3rem;
    }
}

@media (max-width: 640px) {
    .mlSpecial {
        margin-left: 1.3rem;
    }
}


/* .swiper-slide {
    opacity: 0.5;
}

.swiper-slide.swiper-slide-active {
    opacity: 1;
}

.swiper-slide.swiper-slide-next {
    opacity: 1;
} */

.swiper-slide {
    /* opacity: 0.25; */
}

.swiper-slide-visible {
    opacity: 1;
}


/* .swiper-slide-visible:last-of-type {
    opacity: 0.25 !important;
} */

@media (max-width: 480px) {
    .carousel .swiper-slide-active+*+* {
        opacity: 0.5;
    }
}

@media (max-width: 640px) {
    .carousel .swiper-slide-active+*+*+* {
        opacity: 0.5;
    }
}

@media (max-width: 480px) {
    .classTypes .swiper-slide-active+*+*+* {
        opacity: 0.5;
    }
}

@media (max-width: 640px) {
    .classTypes .swiper-slide-active+*+*+* {
        opacity: 0.5;
    }
}


/* .swiper-slide {
    width: 270px !important;
} */

.bgMB {
    background: #f9fafb;
}